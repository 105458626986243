import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdCheckCircle } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				CapturePro Rentals
			</title>
			<meta name={"description"} content={"Ваше найкраще джерело оренди фотообладнання"} />
			<meta property={"og:title"} content={"CapturePro Rentals"} />
			<meta property={"og:description"} content={"Ваше найкраще джерело оренди фотообладнання"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66746f87fdcae00021e3a58e/images/abstract-camera-shutter-logo-illustration-in-trendy-and-minimal-style-png.png?v=2024-06-20T20:10:41.543Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66746f87fdcae00021e3a58e/images/abstract-camera-shutter-logo-illustration-in-trendy-and-minimal-style-png.png?v=2024-06-20T20:10:41.543Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66746f87fdcae00021e3a58e/images/abstract-camera-shutter-logo-illustration-in-trendy-and-minimal-style-png.png?v=2024-06-20T20:10:41.543Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66746f87fdcae00021e3a58e/images/abstract-camera-shutter-logo-illustration-in-trendy-and-minimal-style-png.png?v=2024-06-20T20:10:41.543Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66746f87fdcae00021e3a58e/images/abstract-camera-shutter-logo-illustration-in-trendy-and-minimal-style-png.png?v=2024-06-20T20:10:41.543Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66746f87fdcae00021e3a58e/images/abstract-camera-shutter-logo-illustration-in-trendy-and-minimal-style-png.png?v=2024-06-20T20:10:41.543Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66746f87fdcae00021e3a58e/images/abstract-camera-shutter-logo-illustration-in-trendy-and-minimal-style-png.png?v=2024-06-20T20:10:41.543Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
		</Components.Header>
		<Section md-padding="70px 0 80px 0" quarkly-title="Product-2">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
					background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/66746f87fdcae00021e3a58e/images/abstract-camera-shutter-logo-illustration-in-trendy-and-minimal-style-png.png?v=2024-06-20T20:10:41.543Z) 0 0/contain no-repeat scroll padding-box"
					padding="100px 50px 100px 50px"
					md-margin="0px 0px 50px 0px"
					md-padding="50px 50px 50px 50px"
				>
					<Image
						src="https://uploads.quarkly.io/66746f87fdcae00021e3a58e/images/1-5.jpg?v=2024-06-20T20:10:41.524Z"
						display="block"
						max-width="100%"
						width="350px"
						border-radius="30px"
						srcSet="https://smartuploads.quarkly.io/66746f87fdcae00021e3a58e/images/1-5.jpg?v=2024-06-20T20%3A10%3A41.524Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/66746f87fdcae00021e3a58e/images/1-5.jpg?v=2024-06-20T20%3A10%3A41.524Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/66746f87fdcae00021e3a58e/images/1-5.jpg?v=2024-06-20T20%3A10%3A41.524Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/66746f87fdcae00021e3a58e/images/1-5.jpg?v=2024-06-20T20%3A10%3A41.524Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/66746f87fdcae00021e3a58e/images/1-5.jpg?v=2024-06-20T20%3A10%3A41.524Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/66746f87fdcae00021e3a58e/images/1-5.jpg?v=2024-06-20T20%3A10%3A41.524Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/66746f87fdcae00021e3a58e/images/1-5.jpg?v=2024-06-20T20%3A10%3A41.524Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					padding="0px 40px 0px 40px"
					lg-padding="0px 0 0px 0"
				>
					<Text margin="0px 0px 40px 0px" font="normal 500 42px/1.2 --fontFamily-serifGaramond" sm-margin="0px 0px 30px 0px">
						Ознайомтеся з нашим широким асортиментом фотообладнання та послуг
					</Text>
					<Text margin="0px 0px 50px 0px" font="normal 300 20px/1.5 --fontFamily-sans" color="#656b70">
						У CapturePro Rentals ми надаємо неперевершений спектр послуг, щоб задовольнити всі ваші потреби у фотографії. Незалежно від того, чи є ви професійним фотографом чи захопленим любителем, наші пропозиції створені для того, щоб у вас було все необхідне для успішної зйомки. Від камер високого класу до спеціалізованого освітлення, наше обладнання та послуги створені для підтримки ваших творчих починань.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						lg-align-self="center"
						md-align-self="auto"
						sm-flex-direction="column"
						sm-align-items="center"
						sm-align-self="center"
					/>
				</Box>
			</Box>
		</Section>
		<Section
			padding="60px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			background="--color-darkL2"
			quarkly-title="Advantages/Features-10"
			color="--darkL1"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
				lg-justify-content="flex-start"
			>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
				>
					Комплексна оренда обладнання
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--lightD2"
					width="90%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="left"
					lg-width="100%"
					lg-max-width="720px"
				>
					Ми пишаємося тим, що пропонуємо широкий вибір фотообладнання, яке відповідає широкому спектру стилів і вимог. Наш асортимент ретельно підібраний і регулярно оновлюється, щоб включити новітні технології та провідні бренди в галузі.
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="32px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="48px"
			>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="md"
						icon={MdCheckCircle}
						size="42px"
						color="--light"
						background="--color-indigo"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--headline3" color="--light" margin="0px 0px 6px 0px">
							Камери
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Камери DSLR: наші камери DSLR ідеально підходять як для початківців, так і для професіоналів, і забезпечують чудову якість зображення та універсальність.
							<br />
							<br />
							{"\n "}Серія Canon EOS: відома своєю міцною конструкцією та винятковою продуктивністю в різних умовах зйомки.{"\n"}
							<br />
							<br />
							{" "}Серія Nikon D: відома своїми сенсорами з високою роздільною здатністю та чудовими можливостями роботи в умовах слабкого освітлення.
Бездзеркальні камери: ідеальні для тих, хто шукає компактні та легкі варіанти без шкоди для якості.{"\n"}
							<br />
							<br />
							{" "}Серія Sony Alpha: пропонує відмінне автофокусування, високу швидкість зйомки та яскраве зображення.{"\n"}
							<br />
							<br />
							{" "}Fujifilm серії X: поєднує ретро-дизайн із сучасними технологіями, забезпечуючи чудове відтворення кольорів.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="md"
						icon={MdCheckCircle}
						size="42px"
						color="--light"
						background="--color-indigo"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--headline3" color="--light" margin="0px 0px 6px 0px">
							Лінзи
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Об’єктиви з фіксованою фокусною відстанню: створюйте чіткі високоякісні зображення з фіксованою фокусною відстанню, що ідеально підходить для портретів і зйомки в умовах слабкого освітлення.
 50mm f/1.4: універсальний об’єктив, який чудово підходить для повсякденної зйомки та забезпечує приголомшливе боке.
 85mm f/1.8: ідеально підходить для портретної зйомки, забезпечуючи чудове розмиття фону.{"\n"}
							<br />
							<br />
							Зум-об’єктиви: універсальні об’єктиви, що охоплюють діапазон фокусних відстаней, ідеальні для різних сценаріїв зйомки.
 24-70mm f/2.8: фаворит серед професіоналів завдяки гнучкості та якості зображення.
 70-200mm f/2.8: ідеально підходить для зйомки спорту, дикої природи та портретів, пропонуючи чудову деталізацію та глибину.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="md"
						icon={MdCheckCircle}
						size="42px"
						color="--light"
						background="--color-indigo"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--headline3" color="--light" margin="0px 0px 6px 0px">
							Освітлення та аксесуари
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Студійне освітлення: необхідне для досягнення освітлення професійної якості в контрольованому середовищі.{"\n"}
							<br />
							<br />
							{" "}Софтбокси: створюють м’яке розсіяне світло, яке зменшує різкі тіні.{"\n"}
							<br />
							<br />
							{" "}Кільцеві підсвічування: ідеально підходить для портретної зйомки, забезпечуючи рівномірне, приємне освітлення.{"\n"}
							<br />
							<br />
							Вбудований спалах: покращує освітлення в різних умовах зйомки, від подій у приміщенні до портретів на вулиці.{"\n"}
							<br />
							<br />
							{" "}Speedlights: портативний і потужний, ідеальний для динамічного та гнучкого налаштування освітлення.{"\n"}
							<br />
							<br />
							{" "}Розсіювачі: насадки, які пом’якшують світло від спалаху, запобігаючи різким тіням.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="md"
						icon={MdCheckCircle}
						size="42px"
						color="--light"
						background="--color-indigo"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--headline3" color="--light" margin="0px 0px 6px 0px">
							Спеціалізовані послуги
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							CapturePro Rentals виходить за межі оренди обладнання, пропонуючи ряд спеціалізованих послуг, призначених для підтримки ваших проектів і вдосконалення ваших навичок фотографії.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="md"
						icon={MdCheckCircle}
						size="42px"
						color="--light"
						background="--color-indigo"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--headline3" color="--light" margin="0px 0px 6px 0px">
							Консультація з обладнання
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Наш досвідчений персонал надає індивідуальні консультації, щоб допомогти вам вибрати найкраще обладнання для ваших конкретних потреб. Незалежно від того, чи плануєте ви складну зйомку, чи випробовуєте нові техніки, ми пропонуємо поради експертів, щоб забезпечити найкращі результати.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Icon
						category="md"
						icon={MdCheckCircle}
						size="42px"
						color="--light"
						background="--color-indigo"
						padding="8px 8px 8px 8px"
						border-radius="8px"
						sm-margin="0px 0px 12px 0px"
						margin="0px 0px 20px 0px"
					/>
					<Box align-items="flex-start" display="flex" flex-direction="column" lg-max-width="720px">
						<Text font="--headline3" color="--light" margin="0px 0px 6px 0px">
							Технічна підтримка
						</Text>
						<Text margin="0px 0px 24px 0px" font="--base" color="--lightD2">
							Ми пропонуємо повну технічну підтримку, щоб допомогти вам вирішити будь-які проблеми з нашим обладнанням. Наша команда завжди готова допомогти вам, забезпечивши гладку та ефективну зйомку.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			padding="60px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			background="--color-darkL2"
			quarkly-title="Advantages/Features-10"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
				lg-justify-content="flex-start"
			>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="left"
				>
					Зв’яжіться з нами для отримання додаткової інформації
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--lightD2"
					width="90%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="left"
					lg-width="100%"
					lg-max-width="720px"
				>
					Наша команда готова допомогти вам у виборі ідеального обладнання та послуг для вашого наступного проекту. Завітайте до нас, щоб особисто ознайомитися з нашим широким асортиментом і отримати персоналізовані рекомендації відповідно до ваших потреб.
Ми тут, щоб підтримати вашу творчу подорож і переконатися, що у вас є все необхідне для створення приголомшливих зображень. Звертайтеся сьогодні й дозвольте CapturePro Rentals покращити ваш досвід фотографування.
				</Text>
			</Box>
		</Section>
		<Components.Header />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"666ac2cae8900d0022f35234"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});